import React from 'react';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import DiscoverNavigationActions from 'actions/DiscoverNavigationActions';
import RecentIssuesContainer from 'containers/discover/RecentIssuesContainer';
import { INTERNAL_LOCATION, GRAPHQL_QUERY_NAME } from 'app-constants';
import DiscoverNavigationStore from 'stores/DiscoverNavigationStore';
import useStorePages from 'hooks/useStorePages';
import { RECENT_ISSUES_QUERY } from '../queries';

const RecentIssuesRootContainer = () => {
  const { currentPage } = useStorePages(DiscoverNavigationStore);

  return (
    <RecentIssuesContainer
      baseRoute="discover/issue"
      query={RECENT_ISSUES_QUERY}
      queryName={GRAPHQL_QUERY_NAME.RECENT_ISSUES}
      internalLocation={INTERNAL_LOCATION.DISCOVER_PAGE}
      mountEventName="Discover Page Opened"
      pageEventName="Discover"
      saveRecentIssuesPage={DiscoverNavigationActions.saveRecentIssuesPage}
      data-testid="recent-issues"
      scrollPosition={currentPage.scrollPosition}
    />
  );
};

export default withErrorBoundary(RecentIssuesRootContainer);
