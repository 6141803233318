import Analytics from 'instances/analytics';
import DiscoverNavigationActions from 'actions/DiscoverNavigationActions';
import { history } from 'byebye';
import { INTERNAL_LOCATION, DISCOVER_PAGE_TYPE } from 'app-constants';

const baseRoute = '/discover/issue';

const navigate = (page) =>
  history.navigate(page, {
    trigger: true,
  });

export const updateCurrentPageAndOpenProviderArchive = ({ provider, issue }) => {
  DiscoverNavigationActions.updateCurrentPage({
    issue: {
      bnlId: issue.bnlId,
      id: issue.id,
    },
    provider: {
      providerId: provider.providerId,
      id: provider.id,
    },
  });

  Analytics.track('Issue Archive Opened', {
    internal_location: INTERNAL_LOCATION.PAGED_ISSUE_VIEW,
    issue_id: issue.bnlId,
    provider_id: provider.providerId,
  });

  navigate(`${baseRoute}/${provider.id}`);
};

export const onBackFromIssueView = (previousPage) => {
  const { provider } = previousPage;
  DiscoverNavigationActions.removeLastPage();

  navigate(provider ? `${baseRoute}/${provider.id}` : baseRoute);
};

export const saveCurrentPage = () => {
  DiscoverNavigationActions.saveCurrentPage({
    type: DISCOVER_PAGE_TYPE.ISSUE,
  });
};
