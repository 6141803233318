import React from 'react';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import withRouter from 'react-router/lib/withRouter';
import { shape, string } from 'prop-types';
import { compose } from 'ramda';
import ProviderIssuesContainer from 'containers/discover/ProviderIssuesContainer';
import DiscoverNavigationActions from 'actions/DiscoverNavigationActions';
import DiscoverNavigationStore from 'stores/DiscoverNavigationStore';

const ProviderIssuesRootContainer = ({ params }) => {
  const { graphQLProviderId } = params;

  return (
    <ProviderIssuesContainer
      graphQLProviderId={graphQLProviderId}
      baseRoute="discover/issue"
      saveCurrentPage={DiscoverNavigationActions.saveCurrentPage}
      updateCurrentPage={DiscoverNavigationActions.updateCurrentPage}
      removeLastPage={DiscoverNavigationActions.removeLastPage}
      store={DiscoverNavigationStore}
      data-testid="provider-issues"
    />
  );
};

ProviderIssuesRootContainer.propTypes = {
  params: shape({
    graphQLProviderId: string.isRequired,
  }).isRequired,
};

const enhance = compose(withErrorBoundary, withRouter);

export default enhance(ProviderIssuesRootContainer);
